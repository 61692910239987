import { Text } from "@sqymagma/elements"
import React from "react"
import Quote from "./Quote"
import StyledMarkdown from "./StyledMarkdown"

const PageBodyRenderer = ({ body }) =>
  body
    ? body.map((item, idx) => (
        <>
          {item.type === "text" && (
            <Text as="p" textStyle="body" mb="m" key={idx}>
              <StyledMarkdown source={item.content} />
            </Text>
          )}
          {item.type === "quote" && <Quote key={idx}>{item.content}</Quote>}
          {item.type === "title" && (
            <Text as="h2" textStyle="title05" mt="xxl" mb="m">
              {item.content}
            </Text>
          )}
        </>
      ))
    : ""

export default PageBodyRenderer
