import React from "react"
import { Flex, Box, MaxWidth, Text, Stack } from "@sqymagma/elements"
import Layout from "../components/Layout"
import HeroContainer from "../components/HeroContainer"
import Line from "../components/Line"
import StickySidebar from "../components/StickySidebar"
import RelatedContent from "../components/RelatedContent"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import PageBodyRenderer from "../components/PageBodyRenderer"

const StyledBgImage = styled(BackgroundImage)`
  background-position: top center;
`
const LayoutSimplePage = ({
  image,
  tagName,
  title,
  lead,
  body,
  seoTitle,
  seoDescription,
  seoImage,
  seoImageAlt,
  children,
  revealedFooter = false,
  inverseHeader = false,
}) => {
  const breakpoints = useBreakpoint()

  return (
    <Layout
      inverseHeader={inverseHeader}
      revealedFooter={revealedFooter}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      seoImage={seoImage}
      seoImageAlt={seoImageAlt}
    >
      {image && (
        <StyledBgImage Tag="section" fluid={image}>
          <Box height={{ default: "220px" }} position="relative" />
          <Box
            top="0"
            width="100%"
            height="100%"
            position="absolute"
            css={`
              background: ${p => p.theme.gradient.headerOverlay};
              mix-blend-mode: multiply;
            `}
          />
        </StyledBgImage>
      )}
      <Box position="relative">
        <HeroContainer
          as="section"
          height="auto"
          minHeight={{ default: "360px", m: "480px" }}
          withDecoration
        >
          <Stack
            width={{ default: 1, m: 9 / 12, l: 8 / 12 }}
            vs="m"
            flexDirection="column"
            py={{ default: "xl", m: "4xl" }}
            maxWidth="900px"
          >
            {tagName && (
              <Text as="p" textStyle="smallTightBold" color="text02" caps mb="0">
                {tagName}
              </Text>
            )}
            <Text as="h1" textStyle="display02" mb="0">
              {title}
            </Text>
            {lead && (
              <Text as="h2" textStyle="title04" mb="0">
                {lead}
              </Text>
            )}
          </Stack>
          <Line bleeding={"true"} />
        </HeroContainer>
      </Box>
      <MaxWidth pt={{ default: "xl", m: "4xl" }} pb="xxl" as="article">
        <Flex position="relative">
          {breakpoints.m && <StickySidebar position="sticky" top="32px" />}
          <Box as="div" width={{ default: 1, m: 8 / 12 }} mr={{ default: 0, m: 1 / 12 }}>
            {body && <PageBodyRenderer body={body} />}
            {children && children}
          </Box>
        </Flex>
      </MaxWidth>
      <RelatedContent cols={4} />
    </Layout>
  )
}

export default LayoutSimplePage
