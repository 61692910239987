import React from "react"
import { Text } from "@sqymagma/elements"

const Quote = ({ children, ...props }) => (
  <Text
    className="quote"
    as="blockquote"
    textStyle="quote01"
    color="text02"
    ml={{ default: 0, s: "xl", m: `${(100 / 12) * -1}%` }}
    mr={{ default: 0, s: "m", m: `${(100 / 12) * 3}%` }}
    py={{ default: "l", s: "xl", m: "3xl" }}
    css={`
      * {
        margin: 0;
        padding: 0;
        width: 100%;
      }
      p {
        ${p => p.theme.textStyle.quote01}
      }
    `}
  >
    {children}
  </Text>
)

export default Quote
