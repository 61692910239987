import React from "react"
import LayoutSimplePage from "../components/LayoutSimplePage"
import { privacyPage } from "../../data"

const PolíticaPrivacidad = () => {
  return (
    <LayoutSimplePage
      title={privacyPage.title}
      seoTitle={privacyPage.title}
      body={privacyPage.body}
      footerSimple={true}
    />
  )
}

export default PolíticaPrivacidad
